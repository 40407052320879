
import { mixins, Options, Prop } from 'vue-property-decorator'
import ButtonIcons, {ButtonIcon} from '../../../icons/mixins/buttonIcons'
import {ButtonAlignment, ButtonType} from './types'
import LoadingSpinner from '../../../icons/LoadingSpinner.vue'

@Options({
  components: {
    LoadingSpinner
  }
})
export default class Button extends mixins(ButtonIcons) {
  @Prop({ default: null }) title!: string | null
  @Prop({ default: ButtonType.PRIMARY }) type!: ButtonType
  @Prop({ default: ButtonAlignment.CENTER }) alignment!: ButtonAlignment
  @Prop({ default: false, type: Boolean }) disabled!: boolean
  @Prop({ default: false, type: Boolean }) small!: boolean
  @Prop({ default: false, type: Boolean }) inline!: boolean
  @Prop({ default: false, type: Boolean }) bold!: boolean
  @Prop({ default: true, type: Boolean }) uppercase!: boolean

  @Prop({ default: false }) loading!: boolean
  @Prop({ default: null }) iconLeft!: ButtonIcon | null
  @Prop({ default: null }) iconRight!: ButtonIcon | null

  @Prop({ default: null }) href!: string | null
  @Prop({ default: '_self' }) target!: string
  @Prop({ default: null }) onClick!: ((event: Event) => void) | null

  handleClick(event: Event) {
    if (this.onClick)
      this.onClick(event)
  }

  get classByType() {
    switch (this.type) {
    case ButtonType.PRIMARY:
      return 'brand-primary'
    case ButtonType.TERTIARY:
      return 'brand-tertiary'
    case ButtonType.SECONDARY:
      return 'brand-secondary'
    case ButtonType.ERROR:
      return 'brand-error'
    default:
      return 'brand-primary'
    }
  }

  get alignmentByPropType() {
    switch (this.alignment) {
    case ButtonAlignment.CENTER:
      return 'brand-text-center'
    case ButtonAlignment.START:
      return 'brand-text-start'
    case ButtonAlignment.END:
      return 'brand-text-end'
    default:
      return 'brand-text-center'
    }
  }

  get loadingSpinnerColor(): string {
    switch(this.type) {
    case ButtonType.SECONDARY:
      return 'primary'
    case ButtonType.TERTIARY:
      return 'primary'
    default:
      return 'secondary'
    }
  }
}
