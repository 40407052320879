import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f099ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["target", "href"]
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = {
  key: 0,
  class: "loading-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      ref: "button",
      target: _ctx.href ? _ctx.target : undefined,
      href: _ctx.href ? _ctx.href : undefined,
      class: _normalizeClass(["brand-button", [
        _ctx.alignmentByPropType,
        _ctx.classByType,
        {
          'brand-small': _ctx.small,
          'brand-inline': _ctx.inline,
          'brand-disabled': _ctx.disabled,
          'brand-bold': _ctx.bold,
          'brand-uppercased': _ctx.uppercase,
          'brand-fullRounded': !_ctx.title
        }
      ]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass({'loading-with-text': _ctx.title})
            }, [
              _createVNode(_component_LoadingSpinner, { color: _ctx.loadingSpinnerColor }, null, 8, ["color"])
            ], 2),
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconLeft), {
              class: _normalizeClass(_ctx.small ? 'icon-small' : ''),
              size: 18,
              "fill-current": ""
            }, null, 8, ["class"])),
            _createTextVNode(" " + _toDisplayString(_ctx.title) + " ", 1),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconRight), {
              class: _normalizeClass(_ctx.small ? 'icon-small' : ''),
              size: 18,
              "fill-current": ""
            }, null, 8, ["class"]))
          ], 64))
    ], 10, _hoisted_1)
  ]))
}