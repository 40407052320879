import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a198e592"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.$props.size,
    height: _ctx.$props.size,
    viewBox: "0 0 10 10",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M0.916707 5.58334H7.43254L4.58587 8.43C4.35837 8.6575 4.35837 9.03084 4.58587 9.25834C4.81337 9.48584 5.18087 9.48584 5.40837 9.25834L9.25254 5.41417C9.48004 5.18667 9.48004 4.81917 9.25254 4.59167L5.41421 0.74167C5.18671 0.51417 4.81921 0.51417 4.59171 0.74167C4.36421 0.96917 4.36421 1.33667 4.59171 1.56417L7.43254 4.41667H0.916707C0.595874 4.41667 0.333374 4.67917 0.333374 5C0.333374 5.32084 0.595874 5.58334 0.916707 5.58334Z",
      fill: _ctx.fill,
      class: _normalizeClass({fillCurrent: _ctx.fillCurrent})
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}