export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  ERROR = 'error'
}

export enum ButtonAlignment {
  START = 'text-start',
  CENTER = 'text-center',
  END = 'text-end'
}
