
import {Options, Prop, Vue} from 'vue-property-decorator'

@Options({})
export default class LoadingSpinner extends Vue {
  @Prop({ default: 24 }) size!: number
  @Prop({default: 'secondary'}) color!: 'primary' | 'secondary'

  get strokeColor(): string {
    switch (this.color) {
    case 'primary':
      return 'stroke-primary'
    case 'secondary':
      return 'stroke-secondary'
    default:
      return 'stroke-primary'
    }
  }
}
