import {Options, Vue} from 'vue-property-decorator'
import Arrow from '../Arrow.vue'
import Phone from '../Phone.vue'
import Logout from '../Logout.vue'
import Close from '../Close.vue'
import Check from '../Check.vue'
import ArrowDown from '../ArrowDown.vue'
import ChevronRight from '../ChevronRight.vue'
import ChevronUp from '../ChevronUp.vue'
import ChevronDown from '../ChevronDown.vue'
import Globe from '../Globe.vue'
import Pencil from '../Pencil.vue'
import Chevron from '../Chevron.vue'
import Plus from '../Plus.vue'
import PencilOff from '../PencilOff.vue'
import Info from '../Info.vue'

export enum ButtonIcon {
  ARROW = 'arrow',
  ARROW_DOWN = 'arrow-down',
  CHECK = 'check',
  CLOSE = 'close',
  LOGOUT = 'logout',
  PENCIL = 'pencil',
  PENCIL_OFF = 'pencilOff',
  PLUS = 'plus',
  PHONE = 'phone',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_UP = 'chevron-up',
  CHEVRON_DOWN = 'chevron-down',
  GLOBE = 'globe',
  INFO = 'info'
}

@Options({
  components: {
    [ButtonIcon.ARROW]: Arrow,
    [ButtonIcon.ARROW_DOWN]: ArrowDown,
    [ButtonIcon.CHECK]: Check,
    [ButtonIcon.CLOSE]: Close,
    [ButtonIcon.LOGOUT]: Logout,
    [ButtonIcon.PENCIL]: Pencil,
    [ButtonIcon.PENCIL_OFF]: PencilOff,
    [ButtonIcon.PLUS]: Plus,
    [ButtonIcon.PHONE]: Phone,
    [ButtonIcon.CHEVRON_RIGHT]: ChevronRight,
    [ButtonIcon.CHEVRON_LEFT]: Chevron,
    [ButtonIcon.CHEVRON_UP]: ChevronUp,
    [ButtonIcon.CHEVRON_DOWN]: ChevronDown,
    [ButtonIcon.GLOBE]: Globe,
    [ButtonIcon.INFO]: Info
  }
})
export default class ButtonIcons extends Vue {
}
