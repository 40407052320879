import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e0ca570"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M12.126 8.12579L14.063 6.18879L17.81 9.93579L15.873 11.8738L12.126 8.12579ZM20.71 5.63079L18.37 3.29079C18.1826 3.10454 17.9292 3 17.665 3C17.4008 3 17.1474 3.10454 16.96 3.29079L15.13 5.12079L18.88 8.87079L20.71 7.00079C20.8844 6.81533 20.9815 6.57035 20.9815 6.31579C20.9815 6.06123 20.8844 5.81625 20.71 5.63079V5.63079ZM2 5.00079L8.63 11.6308L3 17.2508V21.0008H6.75L12.38 15.3808L18 21.0008L20 19.0008L4 3.00079L2 5.00079Z",
      fill: _ctx.fill,
      class: _normalizeClass({ fillCurrent: _ctx.fillCurrent })
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}