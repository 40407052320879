
import {Options, Prop, Vue} from 'vue-property-decorator'

@Options({})
export default class Info extends Vue {
  @Prop({ default: 20 }) size!: number
  @Prop({ default: '#212121' }) fill!: string
  @Prop({ default: false, type: Boolean }) fillCurrent!: boolean
}

